import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router/index.js";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import vuetify from "./plugins/vuetify";
// import { initializeApp } from 'firebase/app';
import { auth , get , child, dbRef } from "./firebase";
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";


import { firestorePlugin } from "vuefire";
import DatetimePicker from "vuetify-datetime-picker";
// import VueExcelXlsx from "vue-excel-xlsx";

// new

// import Meta from 'vue-meta'
Vue.use(DatetimePicker);
// Vue.use(VueExcelXlsx);


Vue.use(firestorePlugin);

Vue.use(Vuetify);

Vue.config.productionTip = false;

// const firebaseConfig = {
//   apiKey: "AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg",
//   authDomain: "panel-53aab.firebaseapp.com",
//   databaseURL: "https://panel-53aab.firebaseio.com",
//   projectId: "panel-53aab",
//   storageBucket: "panel-53aab.appspot.com",
//   messagingSenderId: "288872450371",
//   appId: "1:288872450371:web:895aa8d4babf9de2c847f6",
// };

// const app = initializeApp(firebaseConfig);
// console.log(app)


// const messaging = firebase.messaging()

var datosUserGo = {};
var perfilEmpresaGo = {};
var localArrayCecosOwner = [];


console.log("help")
var promisesFB = [];

Vue.config.productionTip = false;
Vue.config.devtools = true // only for productrion
new Vue({
  Vuetify,
  store,
  router,
  vuetify,
  render: (h) => h(App),
  // firebase,
}).$mount("#app");
