import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import {
	auth,
	get, child, dbRef
} from "../firebase";


// import { initializeApp } from 'firebase/app';
// import Home from '../views/Home.vue'

const Dashboard = () => import("../components/Dashboard.vue");
const DashboardThor = () => import("../components/DashboardThor.vue");
const Analytics = () => import("../components/Analytics.vue");
const ScannerThor = () => import("../components/ScannerThor.vue");
// const Scanner = () => import("../components/Scanner.vue");
const Status = () => import("../components/Status.vue");
// const Ingreso = () => import("../components/Ingreso.vue");
const IngresoHub = () => import("../components/IngresoHub.vue");
const Login = () => import("../components/Login.vue");
const CompleteLogin = () => import("../components/CompleteLogin.vue");
const FixLogin = () => import("../components/FixLogin.vue");
const Account = () => import("../components/Account.vue");
const Verify = () => import("../views/Verify.vue");
const NotVerified = () => import("../views/NotVerified.vue");
const Crud = () => import("../views/Crud.vue");
const Offline = () => import("../views/Offline.vue");
const CrudCompanies = () => import("../views/CrudCompanies.vue");
const txsuccess = () => import("../views/Txsuccess.vue");
const txcancel = () => import("../views/Txcancel.vue");
const Reporte = () => import("../components/Reporte.vue");
const VentasEntregas = () => import("../components/VentasEntregas.vue");
const RetirosLocal = () => import("../components/ReporteRetirosLocal.vue");
const RedashDashboard = () => import("../components/RedashDashboard.vue");

// import Register from '../components/Register'

//const Maps = () => import('./Maps.vue')

// global registration
// import VueFormWizard from 'vue-form-wizard'
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// Vue.use(VueFormWizard)
// registration wizard

Vue.use(VueRouter);

const routes = [
	// {
	// 	path: "/ingreso",
	// 	name: "ingreso",
	// 	component: Ingreso,
	// 	meta: {
	// 		requiresAuth: true,
	// 	},
	// },
	{
		path: "/ingresoHub",
		name: "ingresoHub",
		component: IngresoHub,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/scannerthor",
		name: "scannerThor",
		component: ScannerThor,
		meta: {
			requiresAuth: true,
		},
	},
	// {
	// 	path: "/scanner",
	// 	name: "scanner",
	// 	component: Scanner,
	// 	meta: {
	// 		requiresAuth: true,
	// 	},
	// },
	{
		path: "/analytics",
		name: "analytics",
		component: Analytics,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/dashboard",
		name: "dashboard",
		component: Dashboard,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/dashboardThor",
		name: "dashboardThor",
		component: DashboardThor,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/status",
		name: "status",
		component: Status,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/crud",
		name: "crud",
		component: Crud,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/crudcompanies",
		name: "crudcompanies",
		component: CrudCompanies,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/txsuccess",
		name: "txsuccess",
		component: txsuccess,
	},
	{
		path: "/txcancel",
		name: "txcancel",
		component: txcancel,
	},
	{
		path: "/login",
		name: "login",
		component: Login,
		meta: {
			requiresAuth: false,
			outIfLoggedin: true,
		},
	},
	{
		path: "/completeLogin",
		name: "completeLogin",
		component: CompleteLogin,
		meta: {
			requiresAuth: false,
			outIfLoggedin: true,
		},
	},
	{
		path: "/fixLogin",
		name: "fixLogin",
		component: FixLogin,
	},
	{
		path: "/verify",
		name: "verify",
		component: Verify,
		meta: {
			requiresAuth: false,
			outIfLoggedin: false,
		},
	},
	{
		path: "/notverified",
		name: "notverified",
		component: NotVerified,
		meta: {
			requiresAuth: true,
			outIfLoggedin: false,
		},
	},
	// {
	//       path: '/register',
	//       name: 'Register',
	//       component: Register
	//   },

	{
		path: "/offline",
		name: "offline",
		component: Offline,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/account",
		name: "account",
		component: Account,
		meta: {
			requiresAuth: true,
		},

		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.

		//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: "/reporte",
		name: "reporte",
		component: Reporte,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/ventasentregas",
		name: "ventasentregas",
		component: VentasEntregas,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/retiroslocalreporte",
		name: "retiroslocal",
		component: RetirosLocal,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/dashboardFCV",
		name: "dashboardFCV",
		component: RedashDashboard,
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});



let isAuthenticated = false

let isAuthorized = false



auth.onAuthStateChanged(async (user) => {
	// cuando cambia el estado del usser guarda el firebase data . si existe en ref users guarda el rtdb data
	// siempre envia a completeLogin
	console.log(user)
	var self = this;
	if (user != null && user != undefined) {

		console.log(user)
		console.log("TENEMOS USER");
		isAuthenticated = true

		store.dispatch("fetchUser", user);

		let userFromUsersRef = await get(child(dbRef, `users/${user.uid}`))

		if (userFromUsersRef.exists()) {
			console.log("PERFIL--> ", userFromUsersRef.val().perfil);
			console.log("está creado en colección de user. guardamos su datas ");

			store.dispatch("fetchUserDatos", userFromUsersRef.val());
			if (store.getters.user.datosFb.perfil == "autorizado") {
				isAuthorized = true
			}


		} else {
			isAuthenticated = false
			isAuthorized = false
		}


		router.push({
			name: "ingresoHub",
			// params: { type: "authorized" }
		});
	}
	else {
		isAuthenticated = false
		isAuthorized = false
	}
	// router.push({
	//   name: "/completeLogin",
	//  // params: { type: "authorized" }
	// });

})





router.beforeEach(async (to, from, next) => {
	console.log("to:" + to.name);
	// const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	//const outIfLoggedin = to.matched.some((record) => record.meta.outIfLoggedin);

	//console.log(store);
	// var isAuthenticated= store.getters.user.loggedIn
	// console.log(store.getters.user.loggedIn)

	// // console.log(isAuthenticated)
	// console.log("requieres auth?");
	// console.log(requiresAuth);
	console.log("Authenticated??");
	console.log(isAuthenticated);
	console.log("authorized??");
	console.log(isAuthorized);


	// forces the account info completition

	//   if (store != undefined && store != null && store.getters != null && store.getters.user.datosFb!= null  && store.getters.user.datosFb.status=="pending" && router.currentRoute!="/account") {
	//     console.log("dddddd")
	//     //next('/account')
	//   }
	//   else {
	//     console.log("cuenta activa. Continua")
	//   }
	// // forces..




	//if (isAuthorized || (to.name == "completeLogin" || to.name == "fixLogin")) {
	if (isAuthorized) {

		next()

	} else  {
		// no está autorizado 
		if( from.name=="completeLogin" && to.name == "completeLogin") {
			console.log("no hace nada")
		}
		else if (to.name == "completeLogin") {

			console.log("deja avanzar")
			next()
		 }
		 else if (to.name != "completeLogin" && from.name!="completeLogin" ) {
			next("completeLogin");
		} else {
			console.log("no hace nada")
			//next()
		}
	}
	// else if (!isAuthorized) {
	// 	next("/fixLogin")
	// 	// return 0
	// } 


	//return 0
	// }

	// user autorizado



	//	next()
})

export default router;
