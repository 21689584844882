

import { initializeApp } from "firebase/app";
import { getDatabase, get, push,  limitToLast, child, orderByChild, startAt, endAt, ref, query, set, onValue, equalTo } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg",
  authDomain: "panel-53aab.firebaseapp.com",
  databaseURL: "https://panel-53aab.firebaseio.com",
  projectId: "panel-53aab",
  storageBucket: "panel-53aab.appspot.com",
  messagingSenderId: "288872450371",
  appId: "1:288872450371:web:895aa8d4babf9de2c847f6",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);


const database = getDatabase(app);


//console.log("innnnn")

const dbRef = ref(getDatabase(app));
// get(child(dbRef, `users/${userId}`)).then((snapshot) => {






export { db, auth, signInWithEmailAndPassword, push, dbRef , sendSignInLinkToEmail, set, sendEmailVerification, createUserWithEmailAndPassword, child, query, equalTo, orderByChild,startAt, endAt, limitToLast, database, get, isSignInWithEmailLink, signInWithEmailLink, ref, onValue };