<template>
	<!-- :color="barColor" -->
	<v-app id="inspire">
		<v-navigation-drawer
			:disable-resize-watcher="true"
			:dark="$vuetify.theme.dark"
			width="17%"
			v-model="drawer"
			app
			clipped
			bottom
		>
			<!-- <v-card v-if="user.data != null" class="mx-auto" max-width="434" tile color="transparent"> -->
			<!-- <v-img src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"> -->
			<v-row align="start">
				<v-col align-self="start" class="pa-0" cols="12"></v-col>
				<v-col class="py-0" v-if="user != null && user != undefined">
					<!-- <v-list-item>
              <v-list-item-avatar v-if="user.data.photo==null">
               
                <v-icon color="primary">mdi-account-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-img :src="user.data.photo"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="title">{{ user.data.displayName }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>-->
					<!-- <v-img :aspect-ratio="16/9" max-height="120" :src="user.perfilEmpresa.navigationImg">
            HOLAAA{{user.perfilEmpresa.navigationImg}}
          <v-img contain class="mt-2" width="25%" src="./assets/white_logo.png"></v-img>-->
					<!-- </v-img> -->

					<v-list-item class="my-3">
						<v-list-item-content>
							<!-- <v-list-item-title class="title">{{ user.data.displayName }}</v-list-item-title> -->


							<v-list-item-subtitle v-if="user != null && user.data != null">
								<v-icon class="mr-2" color="#1baefc">mdi-account</v-icon>
								<strong>Id:</strong>
								{{ user.data.uid }}
							</v-list-item-subtitle>
							<v-list-item-subtitle v-if="user != null && user.data != null && user.datosFb!=undefined && user.datosFb!=null">
								<v-icon class="mr-2" color="#1baefc">mdi-account</v-icon>
								<strong>Código YZA:</strong>
								{{ user.datosFb.codigo }}
							</v-list-item-subtitle>
							<v-list-item-subtitle v-if="user.datosFb!=undefined && user.datosFb!=null">
								<v-icon class="mr-2" color="#1baefc">mdi-account</v-icon>
								<strong>Nombre:</strong>
								{{ user.datosFb.name }}
							</v-list-item-subtitle>

							<v-list-item-subtitle v-if="user != null && user.data != null ">
								<v-icon class="mr-2" color="#1baefc">mdi-phone</v-icon>
								<strong>Teléfono:</strong>
								{{ user.data.phone }}
							</v-list-item-subtitle>

							<!-- <v-list-item-subtitle>
                <v-icon color="primary" class="mr-2">mdi-information</v-icon>
                <strong>UID:</strong>
                {{ user.data.uid }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon color="green darken-2" class="mr-2">mdi-information</v-icon>
                <strong>Email Verificado:</strong>
                {{ user.data.emailVerified ? "Si" : "No"}}
              </v-list-item-subtitle>-->
						</v-list-item-content>
					</v-list-item>
				</v-col>
			</v-row>
			<!-- </v-img> -->
			<!-- </v-card> -->
			<v-divider></v-divider>

			<!-- <div
        v-else
        class="ma-3"
      >Datos no disponibles. Por favor contacte a soporte en el chat de la ventana inferior</div>-->
			<!-- v-if="user.data.email == 'datasocofar@rayoapp.com' || user.data.email  =='televenta_socofar@rayoapp.com' || user.data.email  == 'ecommerce_socofar@rayoapp.com' || user.data.email  == 'maicao@rayoapp.com'"  -->
			<v-list v-if="user.data != null" dense>
				<!--  <v-list-item @click="drawer = false" link to="/">
          <v-list-item-action>
            <v-icon>mdi-package-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v->Solicitar Rayo</v-<list-item-title>
          </v-list-item-content>
        </v-list-item>-->
		<v-list-item @click="drawer = false" link to="/ingresoHub">
					<v-list-item-action>
						<v-icon>mdi-package-variant</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Ingreso Pedidos</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<!-- <v-list-item @click="drawer = false" link to="/ingreso">
					<v-list-item-action>
						<v-icon>mdi-package-variant</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Ingreso Pedidos (original)</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
<!-- <v-list-item @click="drawer = false" link to="/dashboardThor">
					<v-list-item-action>
						<v-icon>mdi-view-list-outline</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Historial de Pedidos (Thor)</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
				<v-list-item @click="drawer = false" link to="/dashboard">
					<v-list-item-action>
						<v-icon>mdi-view-list-outline</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Historial de Pedidos (original)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- <v-list-item
          @click="drawer = false"
          link
          to="/status"
        >
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Cambiar Estados En Sucursal</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
				<!-- <v-list-item @click="abreLink" link>
					<v-list-item-action>
						<v-icon>mdi-monitor-dashboard</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title
							>Dashboard (Beta, no validada al 100%. Considerar solo como referencia)</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item> -->

			<!--	<v-list-item @click="drawer = false" link to="/reporte">
					<v-list-item-action>
						<v-icon>mdi-monitor-dashboard</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Dashboard</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item @click="drawer = false" link to="/ventasentregas">
					<v-list-item-action>
						<v-icon>mdi-table-large-plus</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Matriz de Ventas y Entregas</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

	<v-list-item @click="drawer = false" link to="/scannerthor">
					<v-list-item-action>
						<v-icon>mdi-barcode-scan</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Retiro de pedidos (Scanner THOR)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-spacer></v-spacer>

			
-->

<v-list-item>
					<v-list-item-action>
						<v-icon>mdi-information</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title> v1.901 (YZA)</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				 
				<!-- <v-list-item @click="drawer = false" link to="/scanner">
					<v-list-item-action>
						<v-icon>mdi-barcode-scan</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Retiro de pedidos (Scanner)</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->

				<!-- Implementa Iframe Redash -->
				<!-- <RedashDashboard /> -->
				<!-- End Implementación Iframe Redash -->

				<!-- Reporte Evento Cyber -->
				<!-- <v-list-item
          v-if="isCyberAccount()"
          @click="drawer = false"
          link
          to="/retiroslocalreporte"
        >
          <v-list-item-action>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Reporte Retiros Local</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
				<!-- End Reporte Evento Cyber -->

				<!-- <v-list-item
          @click="drawer = false"
          link
          to="/analytics"
        >
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

				<!-- <v-list-item
          v-if="user.datosFb.perfil!= 'propios' & user.datosFb.perfil!= 'cecos'"
          @click="drawer = false"
          link
          to="/crud"
        >
          <v-list-item-action>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Administración de Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.datosFb.perfil!= 'propios' & user.datosFb.perfil!= 'cecos'"
          @click="drawer = false"
        >
          <v-list-item-action>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Administración de Empresas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false" link to="/account">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mi cuenta</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
				<!-- <v-list-item
          @click="drawer = false"
          link
          target="_blank"
          href="https://rayoapp.com/contacto.html#form1-11"
        >
          <v-list-item-action>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Convenios para Empresas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false" link target="_blank" href="https://rayoapp.com">
          <v-list-item-action>
            <v-icon>mdi-flash</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Acerca de Rayo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->


			</v-list>
			<p
				class="caption"
				v-if="
					user != undefined &&
						user.datosFb != undefined &&
						user.datosFb.perfil != 'propios' &&
						user.datosFb.perfil != 'cecos'
				"
			>
				Solo admins: {{ this.fcmToken }}
			</p>

			

			<div v-if="user.data != null" class="pa-2">
				<v-btn color="#1baefc" @click="cerrarSesion()" block>Cerrar Sesión</v-btn>
			</div>

			<v-spacer></v-spacer>

			
		</v-navigation-drawer>
		<v-app-bar app clipped-left>
			<v-app-bar-nav-icon v-if="user.data !== undefined && user.data !== null" @click.stop="drawer = !drawer" />
			<!-- <v-img max-width="25" src="https://rayoapp.com/assetsnew/images/copia-de-logo-3-128x127.png"></v-img> -->
			<!-- <v-img contain max-width="120" class="pa-2" src="https://www.rayoapp.com/img/2020/logo_azul.png"></v-img> -->
		
			<v-img  v-if="$vuetify.theme.dark"  contain max-width="90" class="pa-2"
			 src="./assets/yza_logo_white.svg"></v-img>
			 <v-img  v-else  contain max-width="90" class="pa-2"
			 src="./assets/logo_yza.svg"></v-img>

			<!-- <v-toolbar-title class="px-2">Pedidos Rayo:{{this.user.loggedIn }}</v-toolbar-title> -->
			<!-- <v-toolbar-title class="px-2">Rayo Delivery</v-toolbar-title> -->

			<v-spacer></v-spacer>



			<span>
				<v-avatar @click="drawer = !drawer" size="34px" v-if="user.data != null && user.data.photo != null">
					<v-img alt="Avatar" :src="user.data.photo"></v-img>
				</v-avatar>
				<v-avatar @click="drawer = !drawer" size="34px" v-if="user.data != null && user.data.photo == null">
					<v-icon color="primary">mdi-account-circle</v-icon>
				</v-avatar>
				<v-avatar size="34px" v-else>
					<v-icon color="primary">mdi-account-circle</v-icon>
				</v-avatar>
			</span>
			<span
				v-if="user != null && user.data != null && user.data.displayName != undefined"
				class="overline mb-4"
				>{{ user.data.displayName }}</span
			>
			<v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
				<v-icon>mdi-brightness-4</v-icon>
			</v-btn>

			<!-- <v-switch prepend-icon="mdi-brightness-4" v-model="$vuetify.theme.dark"></v-switch> -->

			<!--  -->
		</v-app-bar>
		<v-content>
			<!-- Provides the application the proper gutter -->
			<v-container fluid>
				<v-row justify="center"></v-row>

				<router-view></router-view>
			</v-container>
		</v-content>
		<v-footer style="color: white;" color="#311eee" app>
			
			 Powered by 
			<!-- <a style="color: white;" href="https://rayoapp.com">rayoapp.com</a>  -->
			
				<a target="_blank"  href="https://rayoapp.com" ><v-img contain max-width="70" class="mx-2" src="https://www.rayoapp.com/img/2020/logo_blanco	.png"></v-img></a>
			
		</v-footer>
	</v-app>
</template>
<script>
	// import wb from "./registerServiceWorker";
	import { mapGetters } from "vuex";
import {auth} from "./firebase";

	// import * as firebase from "firebase";
	//   import firebase from 'firebase'


	
	// import { getAuth } from "firebase/auth";
	// const auth = getAuth();

	import RedashDashboard from "./components/RedashDashboard.vue";

	// const configOptions = {
	//   apiKey: 'AIzaSyDlXXRykQOlEvZmrJW_lIiFz7AqdsiiZjk',
	//   authDomain: 'entregasrayo.firebaseapp.com',
	//   databaseURL: 'https://entregasrayo.firebaseio.com',
	//   projectId: 'entregasrayo',
	//   storageBucket: 'entregasrayo.appspot.com',
	//   messagingSenderId: '338070330126',
	//   appId: '1:338070330126:web:cd4922b024d627e33d955f',
	//   measurementId: 'G-RGB5HJ0B1Q'
	// }

	// firebase.initializeApp(configOptions)

	export default {
		components: { RedashDashboard },
		props: {
			source: String,
		},
		data: () => ({
			fcmToken: "",
			textoDialogoGenerico: "",
			tituloDialogoGenerico: "",
			destinoDialogoGenerico: "",

			dialogoGenerico: false,

			drawer: false,
			markers: [1, 2],
			// barColorLight: "#232222",
			// barColorDark: "#ffffff",

			fecha: "Bienvenid@ a Rayo",
			numerouser: 0,
		}),
		methods: {
			isCyberAccount() {
				let userMail = this.user.data.email.toLowerCase();

				if (userMail.includes("cyber")) {
					return true;
				} else {
					return true;
				}
			},
			abreLink: function() {
				window.open(
					"https://datastudio.google.com/u/1/reporting/1gj38Zl_jT1Twgwdzfo9EBbnC0FHr-PJQ/page/qTLQB",
					"_blank"
				);
			},
			showPendingAlert() {
				this.alertaGenerica(
					"Tu Cuenta Aún No Está Activa.",
					"Acontinuación serás redireccionad@ a tu sección de perfil en donde podrás ingresar alguna información faltante para que puedas comenzar a usar Rayo.",
					"this.$router.push({name: 'account'})"
				);
			},
			alertaGenerica(titulo, texto, destino) {
				this.textoDialogoGenerico = texto;
				this.tituloDialogoGenerico = titulo;
				this.destinoDialogoGenerico = destino;
				//this.formNewEmailPassword = false;
				this.dialogoGenerico = true;
			},
			cerrarSesion() {
				var self = this;
				console.log("inicia cierre sesion");
				//firebase.
					auth
					.signOut()
					.then(() => {
						console.log("session closed");
						
					
						self.drawer = false;
						
						
						self.$store.dispatch("fetchUser", null);
						self.$store.dispatch("fetchUserDatos", null);
						window.localStorage.removeItem("emailForSignIn");

						self.$router.push({
								name: "completeLogin",
							});
							
							
						// console.log(self.$router.currentRoute.name);
						// if (self.$router.currentRoute.name == "login") {
						// 	console.log("stay here");
						// } else {
						// 	// help cambiar a pass less
						// 	self.$router.push({
						// 		name: "completeLogin",
						// 	});
						// }
					});
			},
			goDark: () => {
				$vuetify.theme.dark = !$vuetify.theme.dark;
			},
		},
		created() {
			this.$vuetify.theme.dark = true;
		},
		mounted() {
			console.log("mounted app.vue");
			// console.log(firebase);
			// const messaging = firebase.messaging();

			// messaging
			// 	.requestPermission()
			// 	.then(() => {
			// 		//  alert('test2')
			// 		console.log("Notification permission granted.");

			// 		// Get Token
			// 		messaging.getToken().then((token) => {
			// 			// alert(token)
			// 			//      alert('test3 ')
			// 			this.fcmToken = token;
			// 			console.log(token);
			// 		});
			// 	})
			// 	.catch((err) => {
			// 		// alert(
			// 		//   "No pudo conseguir acceso. Para recibir notifciaciones Push debes dar acceso a Rayo Delivery."
			// 		// );
			// 		console.log("Unable to get permission to notify.", err);
			// 	});

			// messaging.onMessage((payload) => {
			// 	console.log("Message received. ", payload);
			// 	// ...
			// });
		},

		computed: {
			...mapGetters({
				user: "user",
			}),

			// barColor() {
			//   if (this.$vuetify.theme.dark) {
			//     return this.barColorDark;
			//   } else {
			//     return this.barColorLight;
			//   }
			// }
		},
		// arreglo: function() {
		//   var xx = user.dataFb;
		//   Object.keys(xx).map(function(key) {
		//     return [Number(key), xx[key]];
		//   });
		// }
	};
</script>